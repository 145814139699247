.sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.link_style a{
    color: black;
    font-weight: bold;
    margin-right: -13px;
}

.logo_style {
    max-width: 80%;
    padding-left: 15px;
    padding-top: 10px;
}
