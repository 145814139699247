@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto:wght@400;500;700&display=swap');

.buttonGreen,
.buttonWhite {
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.1px;
    border-radius: 8px;
}

.buttonGreen {
    color: white;
    background-color: #005A00;
}

.buttonWhite {
    color: #005A00;
    background-color: white;
    border-color: #005A00;
}

.buttonGreen:hover {
    color: white;
}

.buttonWhite:hover  {
    color: #005A00;
}