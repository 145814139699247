.pagination {
    :global(.disabled .page-link) {
        background-color: #919eab;
        border-color: transparent;
        color: #c4cdd5;
    }
    :global(.page-item:not(:last-child)) {
        margin-right: 8px;
    }
    li > :global(.page-link) {
        border-radius: 4px;
    }
    :global(.active .page-link) {
        background-color: white;
        border-color: #0c7800;
        color: #0c7800;
    }
    :global(:not(.active):not(.disabled) .page-link) {
        background-color: white;
        border-color: #dfe3e8;
        color: black;
    }
    :global(.page-item:last-child),
    :global(.page-item:first-child) {
        :global(.page-link span:first-child) {
            font-weight: bold;
            padding: 0px 2px;
        }
    }
}
