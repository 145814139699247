.imagePreview {
    max-width: 300px;
    max-height: 200px;
}

th {
  border: 1px solid black !important;
}

td {
  border: 1px solid black !important;
  text-align: center;
  vertical-align: middle !important;
}

table {
  margin-top: 20px;
}
