.wrapper {

}

.containerWrapper {
    margin: 10px;
    padding: 15px;
    border-radius: calc(.25rem);
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 3px 14px 2px rgba(0,0,0,.12);
}

.title {
    font-size: 15px;
}