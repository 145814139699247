$fulfillment-background-color: #48744D;
$fulfillment-text-color: #E6E1E5;
$fulfillment-text-color-2: #A8AAAD;
$fulfillment-text-color-3: #8D8D8D;
$fulfillment-text-color-4: #767676;
$fulfillment-text-color-5: #FFFBFE;
$fulfillment-text-color-6: #EBEBEB;
$dark-green: #0D5E33;
$dark-green-2: #0C7800;
$dark-green-3: #005A00;
$blue: #2966A9;
$dark-red: #BE3434;
$error: #E65C5C;
$purple: #8F29A9;
$dark-grey: #545454;
$orange: #DF6207;
$orange-2: #FD8D25;
$light-grey: #D5D5D5;
$light-white: #F5F6FA;
$dark-cyan: #103A39;