.tableWrapper {
    border-spacing: 0;
    border: 1.5px solid black;
    tr:nth-child(2n) {
      background-color: #fafafa;
    }
    th, td {
      border-color: black;
    }
}

