.loadingSpinner {
    display: flex;
    justify-content: center;
}

.verticalButtonsWrapper {
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    display: flex;
}

.horizontalWrapper {
    align-items: flex-start;
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.formControl {
    width: 25vw;
}

.formStyle {
    background-color: aliceblue;
    padding: 24px 12px 12px 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
    margin-top: 12px;
}

.filterButton {
    margin-right: 16px;
    width: 100px;
}

.recipeTagList {
    display: flex;
    justify-content: space-between;
}

.centeredButtonGroup {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.ruleSetList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.uploadFileStatus {
    font-size: small;
}

.url {
    text-decoration: underline;
}

.errorMessage {
    color: red;
    font-size: small;
    display: flex;
    justify-content: center;
}

.largeErrorMessage {
    color: red;
    display: flex;
    justify-content: flex-start;
}

.editButton {
    background-color: rgb(22, 22, 22);
    color: white;
    padding: 8px 20px 8px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: small;
}

.editButton:hover {
    color: white;
    text-decoration: none;
}

.overlaySpinner {
    position: fixed;
    top:0;
    right: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(30, 30, 30, 0.492);
    color: white;
    z-index: 100;
}

.paginationContainer {
   right:20;
}


.specialBold {
    font-weight: bold;
}

.recipeTagBtn {
    margin-right: 16px;
}