
.loaderModal {
    background-color: rgba(0, 0, 0, 0.5); 
  }
  
  .loaderModalBody {
    background-color: #fff; 
    border-radius: 10px;
    padding: 20px; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  }
  
  .loaderModalTitle {
    color: #333; 
    margin-top: 20px;
  }
  
  .loaderModalMessage {
    color: #666; 
    margin-top: 10px;
  }


@keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .blinking {
    animation: blink 1.5s linear infinite;
  }
  
  
  