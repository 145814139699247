.c-button {
    padding: 8px 32px 8px 32px;
    border: none;
    border-radius: 8px;
    min-width: 110px;
}

.search-button {
    color: white;
    background-color: rgba(12, 120, 0, 1);
}

.reset-button {
    color: rgba(168, 170, 173, 1);
    background-color: white;
    border: 0.5px solid rgba(168, 170, 173, 1);
}

.c-button:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.c-button:focus {
    outline: none;
}