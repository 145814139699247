.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding: 0 20px;
}

.wrapper.height{
    height: 100%;
}

.wrapper.responsive {
    overflow: auto;
}

.navbar {
    flex: 1;
}

.mdWrapper {
    height: 100%;
    flex-direction: row;
    display: flex;
}

.mdWrapper:not(.responsive) {
    overflow: hidden;
}

.master {
    flex: 1;
    height: 100%;
    overflow: auto;
}

.detail {
    flex: 1;
    height: 100%;
    overflow: auto;

}

.detail:hover {
    width: 1000px;
}

.filter {
    flex: 1;
    margin: 10px 0;
}

.loadingIndicator{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}