html,body,#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-text {
  font-size: 15px;
}

.body-div {
  margin: 10px;
  font-size: 15px;
}

.internal-body-div {
  margin-bottom: 10px;
  padding: 15px;

  border-radius: calc(.25rem);
  border: 0.5px solid #ebebeb;
  box-shadow: 0 3px 14px #ebebeb;
}

.internal-upload-div {
  padding: 15px;
  background-color: #f8fafd;
  border-radius: calc(.25rem);
}

table, td, tr, th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

table thead th {
      position: sticky !important;
      z-index: 0 !important;
      top: 0;
      background-color: gainsboro;
  }

/* add spacing of form-control, if they have label before, in form-inline */
.form-inline > .form-group > .form-control:not(:first-child) {
  margin-left: 5px;
}

/* add spacing of form-group, except for first child, in form-inline */
.form-inline > .form-group:not(:first-child) {
  margin-left: 10px;
}

body .react-confirm-alert-overlay {
  z-index: 2000;
}

.add-data {
    background-color: #fff;
    border-top: 1px double #1e1e1e;
    padding: 20px 10px 25px 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    box-shadow: 4px 4px 3px #999999;
}

.btn-link {
    color: #3579F7 !important;
}

.form-ctr .custom-control:has(#switch-using-banner) {
    z-index: 0;
}

.cp_tree-table .cp_tree-table_header {
    color: rgba(55, 53, 47, 0.6);
    background: rgba(242, 241, 238, 0.6);
    border: 1px solid rgba(55, 53, 47, 0.09);
    align-items: center;
}
