.sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 0 0 0 5px;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.linkStyle a {
    color: black;
    font-weight: bold;
    margin-right: -13px;
}

.logo {
    padding-bottom: 0px;
}

.logoStyle {
    max-width: 80%;
    padding-left: 10px;
    padding-top: 10px;
    margin-top: 10px;
}

.logoSubtitle {
    padding-top: 0px !important;
    font-size: 15px;
    color: #3E6342 !important;
    margin: auto;
}

.navLinkStyle {
    padding-left: 10px;
    font-size: 14px;
    color: black !important;
    width: 90%;
    padding: 10px 15px !important
}

.navLinkStyleActive {
    padding-left: 10px;
    font-size: 14px;
    color: white !important;
    background-color: #3E6342 !important;
    width: 90%;
    padding: 10px 15px !important;
}

.spacer {
    height: 20px;
}