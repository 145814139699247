.title {
    font-size: 38px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0 10px 0;
    color: #000000;
    margin-top: 10px;
}

.tabTitle>a {
    border: none !important;
}

/* Adjustment Page */
.createButton {
    background-color: #0D5E33;
    border-color: #0D5E33;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.adjustmentPageTitle {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding: 10px 15px;
}

.formContent {
    margin: 0 15px;
}

/* Table */
.tableCategoryNameHeader {
    font-size: 14px;
    color: #000000;
    text-align: start;
}

.tableCategoryNameSubHeader {
    font-size: 13px;
    color: #3a3a3a;
}

.tableTest>div {
    overflow-x: hidden !important;
}

.tableTest th {
    background-color: white;
    border: 1px solid black;
    text-align: left;
}

.tableTest td {
    background-color: white;
}

.tableTest table {
    background-color: white;
    border: 1px solid black;
    border-collapse: collapse;
}