#sidebar-wrapper{
    min-height: 100vh !important;
    width: 20vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
      height:100%;
}

@media (max-width: 1020px) {
    .table_dashboard {
        margin-top: 0px;
    }
}

@media (min-width: 1020px) {
    .table_dashboard {
        margin-top: -100px;
    }
}

#page_content_wrapper {
    min-width: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.headerName {
    font-weight: bold;
    font-family: sans-serif;
    padding: 20px 0 0 10px;
}

.searchBar {
    width: 150%;
    margin-left: 10px;
}

.navbar_picking {
    padding: 0px 0px 0px 0px !important;
    height: 100% !important;
    max-width: 17%;
}