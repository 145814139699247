.map {
  height: 100%;
  width: 100%;
}

.mapHidden {
  display: none;
}

.mapHeader {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  height: 50px;
}

.newPolygon {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.outerDiv {
  position: relative;
  height: 80%;
  width: 100%;
}

.outerDivMapPoint {
  position: relative;
  height: 90%;
  width: 100%;
}

.search-div {
  flex-grow: 1;
  display: flex;
}

.search-input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
}

.search-box:focus {
  box-shadow: unset !important;
  border: 1px solid rgba(0,0,0,.125) !important;
  border-right: none !important;
  outline: none;
}

.search-btn:focus {
  box-shadow: unset !important;
}

.search-btn:focus {
  box-shadow: unset !important;
}

.search-div {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
}

.search-box {
  height: 40px !important;
  border: 1px solid rgba(0,0,0,.125) !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: none !important;
  flex-grow: 1;
  box-shadow: none !important;
  -webkit-appearance: none;
}

.search-btn {
  background-color: white !important;
  color: black !important;
  width: 40px;
  border: 1px solid rgba(0,0,0,.125) !important;
  border-left: none !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding: 10px 10px !important;
}

.hideComponent{
  display: none;
}
