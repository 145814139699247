.hover-cursor:hover{
    cursor:pointer;
}

.overflow-y{
    overflow-y: scroll;
}

#time-series-month-picker{
    height: 10%;
}

.hide-collection{
    width: 70%;
    height: 7vh;
    font-size: 1.1em;
}