/**
 * https://stackoverflow.com/questions/15530850/method-to-show-native-datepicker-in-chrome/45461709#45461709
 */
input[type="date"],
input[type="datetime-local"],
input[type="time"] {
    position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after,
input[type="datetime-local"]:after,
input[type="time"]:after
{
    content: "\25BC";
    color: #555;
    padding: 0 5px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after,
input[type="datetime-local"]:hover:after,
input[type="time"]:hover:after{
    color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator{
    position: absolute;
    top: 0;
    /*left: 100px; differentiate for each input different from copy-pasted solution. give space to manual type */
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

/* enable the manual typing instead of blocking it and shows popup */
input[type="date"]::-webkit-calendar-picker-indicator {
    left: 100px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    left: 50px;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    left: 150px;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-inner-spin-button
{
    z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button,
input[type="datetime-local"]::-webkit-clear-button,
input[type="time"]::-webkit-clear-button{
    z-index: 1;
}