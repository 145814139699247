.modal {
    width: 70%;
    max-width: none !important;
}

.row{
    margin: 0;
}

.formFieldPadding {
    margin-left: 0;
    margin-right: 0;
  }
