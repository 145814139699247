.mainContainer {
  min-height: 100vh;
}

.modal {
  width: 30%;
  max-width: 30% !important;
}

.modal :global(.modal-content) {
  border-radius: 10px;
}

.modalHeader {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #F7FAFD;
  border-bottom: none;
}