
.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.button_style {
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.button_style_action {
    width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;

}

.table_style {
    width: 100%;
}

.inside_table_style {
    width: 100%;
    border: none;
}

.column_style {
    padding: 20px;
    text-align: center;
}

.column_style_cdg_id {
    width: 8%;
}

.column_style_action {
    padding-top: 15px;
    padding-bottom: 15px;
}

.column_style_cdg_members {
    text-align: center;
    width: 20%;
}

.column_style_cdg_members_scrollable {
    height:210px ;
    overflow-y: scroll;
    padding: 10px 3px 20px 20px;
}

.column_style_edit_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search_line{
    display: flex;
    justify-content: space-between;
}

.search_form{
    flex: 1;
}

.pagination {
    margin-top: 5px;
    margin-bottom: 5px;
    float: right;
}

.spinner {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.create_form_style {
    padding-right: 30px;
    margin-top: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    
}

.desc_text_style{
    font-size: small;
    color: gray;
}

.dnd_item_row_style {
    display: flex;
    width: 100%;
}

.row_form {
    width: 800px;
}

.row_mid_form {
    width: 350px;
}

.back_next_button_position{
    display: flex;
    justify-content: flex-end;
}

.submit_button_style{
    width: 100%;
}

.field_style{
    border: 0px;
    pointer-events: none;
    margin-top: 4px;
    margin-bottom: 4px;
}