.modal {
    width: 90%;
    max-width: 90% !important;
}

.error {
    color: #ff0000;
}

.success {
    color: #008000;
}

.row {
    margin-top: 10px;
}

.smallText {
    font-size: 12px;
}

.smallColumn {
    min-width: 120px;
    z-index: 1;
}

.mediumColumn {
    min-width: 150px;
    z-index: 1;
}

.bigColumn {
    min-width: 250px;
    z-index: 1;
}

.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.closeBtn {
    color: red;
    font-weight: bolder;
    cursor: pointer;
}

.label {
    background-color: cornflowerblue;
    color: white;
    border-radius: 10px;
    padding: 4px 16px;
}

.labelCopy {
    background-color: #007bff;
    color: white;
    border-radius: 10px;
    padding: 4px 16px;
    cursor: pointer;
}

.labelWarehouse {
    background-color: #16213e;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 4px 16px;
}

.labelAddress {
    background-color: #9aa4c0;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 4px 16px;
}

.labelEta {
    background-color: #0f3460;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 4px 16px;
}

.labelInfo {
    background-color: #e94560;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 4px 16px;
}


.fixedTable {
    padding-top: 160px;
}

.fixedDetailTable {
    padding-top: 128px;
}

.fixedCol {
    height: 135px;
}

.reactDatepickerPopper {
    z-index: 1000;
}

.readOnly {
    background-color: #fafafa !important;
}

.shortenText {
    display: block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.btnDivLeft,
.editBtnDiv {
    justify-content: flex-end;
    display: flex;
}


.formInput {
    display: block;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    padding: 6px 12px;
    min-width: 50%;
}

.formInput[readonly] {
    background-color: #dddddd;
    opacity: 1;
}

.modalLarge {
    max-width: 100vw;
}

.modalTitle {
    font-size: 18px;
    font-weight: 600;
}

table thead th {
    position: sticky !important;
    z-index: 1;
    top: 0;
    background-color: gainsboro;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffffff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #0C7800FF;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #0C7800FF;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #0C7800FF;
}