@import "../TransferColorPalette.module";

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navLink {
  display: block;
  padding: 0.5rem 2rem;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.navTabs {
  border-bottom: 0.1rem solid silver;

  .navItem {
    margin-bottom: -0.1rem;
  }

  .navLink {
    color: silver;
  }

  .navLink:hover {
    color: $dark-green;
  }

  .navLink.active,
  .navItem.show .navLink {
    color: $dark-green;
    border-bottom: 0.15rem solid $dark-green;
    font-size: 16px;
  }
}