.recipeTagFormHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    align-items: flex-end;
}

.recipeTagInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
}

.customButton {
    margin-left: 16px;
}

.inputCell {
    border-width: 1px;
    border-color: rgb(216, 216, 216);
    padding: 8px;
    width: 100%;
    border-radius: 4px;
}

.selectCellPadding {
    padding: 4px;
}

.recipeContainer {
    width: 50%;
}

.priorityContainer {
    width: 20%;
}

.tableHead {
    background-color: rgb(224, 223, 223);
    padding: 12px;
    text-align: center;
    border: 0.5px solid grey;
    width: 50%;
    font-weight: bold;
}

.row {
    display: flex;
}

.tableContent {
    text-align: center;
    border: 0.5px solid grey;
    width: 50%;
    padding: 8px;
}

.plusButton {
    height: 100%;
    color: grey;
    vertical-align: "center";
}

.plusButton:hover {
    color: black;
    cursor: pointer;
}

.plusButton:active {
    border: 2px solid rgb(93, 134, 217);
    border-radius: 5px;
}

.overlaySpinner {
    position: fixed;
    top:0;
    right: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(30, 30, 30, 0.492);
    color: white;
    z-index: 100;
}

.errorField {
    background-color: rgb(250, 147, 147);
}

tr {
    text-align: center;
    height: 35px;
    font-size: 14px;
}

.exportCsvButton {
    color: white;
    font-size: 14px;
    background-color: green;
    padding: 6px 8px;
    border-radius: 4px;
}

.exportCsvButton:hover{
    text-decoration: none;
    color: white
}

.centeredButtonGroup {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}
