.datePicker {
  width: 100%;
  display: block;
}

.form label {
  display: block;
}

.row {
  margin: 0;
}

.row span {
  margin-right: 15px
}

.link {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.categoryImage{
  height: 32px;
}
.categoryName{
  padding: 2px;
}
.categoryVisible{
  float: right;
  border: 1px black solid;
  border-radius: 10px;
  padding: 2px 10px;
}
.categoryPriority{
  height: 16px;
  width: 16px;
  border: 1px black solid;
  border-radius: 10px;
  padding: 2px;
}