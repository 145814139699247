.product-table-container{
    width: 100%;
    overflow-x: scroll;
}

.product-table table, th, td {
    border: 1px solid rgba(234, 234, 234, 1) !important;
}

.product-table th {
    text-align: center;
    background-color: rgba(249, 248, 249, 1) !important;
    font-size: 15px;
    padding: 5px;
}

.product-table td {
    text-align: center;
    min-width: 125px;
    font-size: 14px;
    padding: 5px;
}

.special-column {
    max-width: 20px !important;
    min-width: 10px !important;
    text-align: center;
}

.bulk-action-button{
    width: 100%;
    min-width: 80px;
    margin-right: 5px;
}

.bulk-action-button:hover {
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.24), 0 12px 25px 0 rgba(0, 0, 0, 0.19);
}

.bulk-action-button:focus {
    outline: none;
}

.approve-button{
    color: white;
    background-color: #0E7800;
}

.cancel-button{
    background-color: #aa1717;
    color: white;
}