@import "../TransferColorPalette.module.scss";

.title {
    font-size: 38px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0 10px 0;
    color: #000000;
    margin-top: 10px;
}

.tabTitle>a {
    border: none !important;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.adjustmentPageTitle {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding: 10px 15px;
}

.formContent {
    margin: 0 15px;
}

/* Table */
.tableCategoryNameHeader {
    font-size: 14px;
    color: #000000;
    text-align: start;
}

.tableCategoryNameSubHeader {
    font-size: 13px;
    color: #3a3a3a;
}

.tableTest>div {
    overflow-x: hidden !important;
}

.tableTest th {
    background-color: white;
    border: 1px solid black;
    text-align: left;
}

.successBadge {
    color: #005A00;
    background-color: #F2F7F2;
    border: 1px solid #005A00;
    border-radius: 4px;
    width: 50%;
}

.failedBadge {
    color: #E65C5C;
    background-color: #FFF2F2; 
    border: 1px solid #E65C5C;
    border-radius: 4px;
    width: 50%;
}

.notRunBadge {
    color: #767676;
    background-color: #F8FAFD;
    border: 1px solid #A8AAAD;
    border-radius: 4px;
    width: 50%;
}

.navigator {
    margin-top: 5vh;
    color: grey;
    width: 100%;
}

.navigator:hover {
    cursor: pointer;
}

.navigatorLink {
    text-decoration: underline;
    display: inline;
}

.navigatorLink:hover {
    color: green;
}

.hoverable:hover {
    cursor: pointer;
}

.statusSubtext {
    color: #767676;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    justify-content: start;
    display: inline-block;
    white-space: nowrap;
}

.tableRow {
    border-top: none;
    border-left: none;
    border-right: none;
}

.title {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0 10px 0;
    color: #000000;
    margin-top: 10px;
}

.tabTitle>a {
    border: none !important;
}

/* Adjustment Page */
.createButton {
    background-color: #0D5E33;
    border-color: #0D5E33;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.adjustmentPageTitle {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding: 10px 15px;
}

.formContent {
    margin: 0 15px;
}

.tableTest>div {
    overflow-x: hidden !important;
}

.tableTest th {
    background-color: white;
    border: 1px solid black;
    text-align: left;
}

.tableTest td {
    background-color: white;
}

.tableTest table {
    background-color: white;
    border: 1px solid black;
    border-collapse: collapse;
}

.linkText {
    text-decoration: underline;
    color: darkgreen;
}

.linkText:hover {
    color: green;
    cursor: pointer;
}

.btnOk, .btnOk:hover {
    background-color: $dark-green;
    color: white;
    border-color: white;
}

.btnOk:disabled {
    background-color: #A8AAAD;
    color: white;
    border-color: #A8AAAD;
}

.btnOutlineOk {
    background-color: white;
    color: $dark-green;
    border-color: $dark-green;
}

.btnOutlineOk:hover {
    background-color: $dark-green;
    color: white;
    border-color: white;
}

.btnCancel, .btnCancel:hover {
    background-color: white;
    color: $dark-green;
    border-color: $dark-green;
}

.borderlessTable {
    width: 100%;
    border-spacing: 0;
    tr {
        border: 1px solid #eaeaea;
    }
    tr:nth-child(2n) {
      background-color: #fafafa;
    }
    th, td {
        padding-left: 1vw;
        text-align: left;
        border: none !important;
        border-color: black;
        background-color: white !important;
    }
}

.subText {
    font-size: 12px;
    color: #767676;
}

.shipmentPage {
    font-family: 'Roboto';
}