.navbar {
  height: 52px;
  padding: 8px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #212529;
}

.container {
  height: 100%;
}

.content {
  height: calc(100% - 52px);
}